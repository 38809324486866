.about,
.faq,
.terms,
.privacy,
.disclaimer,
.contact {
    background-color: white;
    border-radius: 20px;
    font-family: 'Times New Roman', Times, serif;
    color: black;
    padding: 20px;
}

.about h3,
.faq h3,
.terms h3,
.privacy h3,
.disclaimer h3,
.contact h3 {
    font-size: 18px;
    font-weight: bold;
}

.about p,
.faq p,
.terms p,
.privacy p,
.disclaimer p,
.contact p {
    font-size: 20px;
}

.contact-button {
    background-color: rgb(17, 143, 17) !important;
    border-radius: 20px !important;
    font-size: 18px;
}