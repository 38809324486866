/* On screens that are 992px or less, set the background color to blue */
@media screen and (max-width: 992px) {
    

}

/* On screens that are 600px or less, set the background color to olive */
@media screen and (max-width: 768px) {
    .section1-btn {
        font-size: 30px;
    }
    .footerButton .last-button{
        width: 100%;
      } 
}